/* eslint-disable @typescript-eslint/member-ordering */
// noinspection JSUnusedGlobalSymbols

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { ApiUserManagementService } from '../../routes/user-manager/services/api-user-management.service';
import { RolesActions, RolesSelector } from '@store/roles-store/index';
import { EMPTY, switchMap } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class RolesEffects {
    constructor( private action$: Actions, private store: Store, private api: ApiUserManagementService, private toaster: ToastrService ) {
    }

    loadRoles = createEffect( () => this.action$.pipe(
        ofType( RolesActions.loadRoles, RolesActions.loadAll ),
        switchMap( () => this.api.getAllRoles().pipe(
            map( roles => RolesActions.setRoles( { roles } ) ),
            catchError( err => {
                console.error( 'Error in loading roles', err );
                return EMPTY;
            } )
        ) )
    ) );

    loadPolicies = createEffect( () => this.action$.pipe(
        ofType( RolesActions.loadPolicies, RolesActions.loadAll ),
        switchMap( () => this.api.getAllPolicies().pipe(
            map( policies => RolesActions.setPolicies( { policies } ) ),
            catchError( err => {
                console.error( 'Error in loading policies', err );
                return EMPTY;
            } )
        ) )
    ) );


    addRole = createEffect( () => this.action$.pipe(
        ofType( RolesActions.addRole ),
        switchMap( ( action ) => this.api.addRole( action.role ).pipe(
            map( roles => RolesActions.setRoles( { roles } ) ),
            tap( () => this.toaster.success( 'Ruolo aggiunto con successo', 'Ruolo aggiunto' ) ),
            catchError( err => {
                console.error( 'Error in saving role', err );
                return EMPTY;
            } )
        ) ),
    ) );

    editRole = createEffect( () => this.action$.pipe(
        ofType( RolesActions.editRole ),
        switchMap( ( action ) => this.api.editRole( action.role ).pipe(
            map( roles => RolesActions.setRoles( { roles } ) ),
            tap( () => this.toaster.success( 'Ruolo modificato con successo', 'Ruolo modificato' ) ),
            catchError( err => {
                console.error( 'Error in editing role', err );
                return EMPTY;
            } )
        ) ),
    ) );

    deleteRole = createEffect( () => this.action$.pipe(
        ofType( RolesActions.deleteSelectedRole ),
        concatLatestFrom( () => this.store.select( RolesSelector.selectSelectedRole ) ),
        switchMap( ( [ , role ] ) => this.api.deleteRole( role ).pipe(
            map( roles => RolesActions.setRolesResetSelected( { roles } ) ),
            tap( () => this.toaster.success( 'Ruolo eliminato con successo', 'Ruolo eliminato' ) ),
            catchError( err => {
                console.error( 'Error in deleting role', err );
                return EMPTY;
            } )
        ) )
    ) );

    changeRolePolicies = createEffect( () => this.action$.pipe(
        ofType( RolesActions.changeRolePolicies ),
        switchMap( ( action ) => this.api.associatePoliciesToRole( action.role, action.policies.map( p => p.name ) ).pipe(
            map( role => RolesActions.setRole( { role } ) ),
            tap( () => this.toaster.success( 'Politiche di accesso modificate con successo', 'Politiche modificate' ) ),
            catchError( err => {
                console.error( 'Error in setting policies to role', err );
                return EMPTY;
            } )
        ) )
    ) );
}
